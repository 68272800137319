export function cx() {
  for (var _len = arguments.length, cssClasses = new Array(_len), _key = 0; _key < _len; _key++) {
    cssClasses[_key] = arguments[_key];
  }

  return cssClasses.reduce(function (acc, className) {
    if (Array.isArray(className)) {
      return acc.concat(className);
    }

    return acc.concat([className]);
  }, []).filter(Boolean).join(' ');
}