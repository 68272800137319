import instantsearch from "instantsearch.js";
import { searchBox, hits, configure } from "instantsearch.js/es/widgets";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
// import algoliasearch from "algoliasearch";
import dotenv from "dotenv";
dotenv.config();

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.TYPESENSE_SEARCH_API_KEY, // search-only-api-key
    nodes: [
      {
        host: "typesense.aarosjob.in",
        port: 443,
        protocol: "https",
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "title",
    sort_by: "_text_match:desc",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const hitDiv = document.querySelector("#hits");

const search = instantsearch({
  searchClient,
  indexName: "jobTitles",
  future: { preserveSharedStateOnUnmount: true },
});

search.addWidgets([
  searchBox({
    container: "#searchbox",
    placeholder: "Search Job Titles",
    showReset: false,
    showSubmit: false,
    queryHook(query, search) {
      if (query != "") {
        hitDiv.classList.remove("d-none");
      } else {
        hitDiv.classList.add("d-none");
      }
      search(query);
    },
  }),
  hits({
    container: "#hits",
    templates: {
      item(hit, { html, components }) {
        return html`
          <a href="https://app.aarosjob.in/search/${hit.title}" target="_blank"
            ><span>
              ${components.Highlight({
                attribute: "title",
                hit,
              })}</span
            ><i class="fa fa-external-link"></i>
          </a>
        `;
      },

      empty(results, { html, components }) {
        return html`
          <a
            href="https://app.aarosjob.in/search/${results.query}"
            target="_blank"
            class="no-result"
            ><span> Search "${results.query}"</span
            ><i class="fa fa-external-link"></i>
          </a>
        `;
      },
    },
  }),
  configure({
    hitsPerPage: 5,
  }),
]);

search.start();
