import { db } from "./firebaseConfig";
import { collection, query, orderBy, getDocs, limit } from "firebase/firestore";

const hamburgerBtn = document.querySelector(".hamburger-icon");
const sidebar = document.querySelector(".sidebar");
const closeSidebarBtn = document.querySelector(".btn-sidebar-close");
const showSidebar = () => {
  sidebar.classList.remove("d-none");
};

const hideSidebar = () => {
  sidebar.classList.add("d-none");
};

hamburgerBtn.addEventListener("click", showSidebar);

closeSidebarBtn.addEventListener("click", hideSidebar);

sidebar.addEventListener("click", hideSidebar);

const fetchJobs = () => {
  console.log("Fetching data....");
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(db, "jobs"),
        orderBy("createdOn", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      let jobListHTML = "";
      let i = 0;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const {
          jobID,
          title,
          location,
          company: { name: comapnyName },
        } = doc.data();

        if (i === 0) {
          jobListHTML +=
            '<div class="m-0 job-opening"  data-jobID="' +
            jobID +
            '"><h3 class="job-title">' +
            title +
            "</h4><p>" +
            comapnyName +
            "</p><p>" +
            location.join(", ") +
            "</p></div>";
        } else {
          jobListHTML +=
            '<div class="job-opening" data-jobID="' +
            jobID +
            '"><h3 class="job-title">' +
            title +
            "</h4><p>" +
            comapnyName +
            "</p><p>" +
            location.join(", ") +
            "</p></div>";
        }

        if (i === 9) {
          resolve(jobListHTML);
        }
        i++;
      });
    } catch (e) {
      reject(e);
    }
  });
};

$(".btn-next").on("click", function () {
  const width = 250 + 24; //width + padding
  var scr = $(".row1").scrollLeft();
  $(".row1").animate(
    {
      scrollLeft: scr + width,
    },
    200
  );
});

$(".btn-prev").on("click", function () {
  const width = 250 + 24; //width + padding
  var scr = $(".row1").scrollLeft();
  $(".row1").animate(
    {
      scrollLeft: scr - width,
    },
    200
  );
});

window.onload = () => {
  console.log("page is fully loaded. Fetch jobs data from firestore");
  const jobRow = document.querySelector(".row1");
  console.log(jobRow);
  fetchJobs().then(
    (res) => {
      jobRow.innerHTML = res;
      $(".job-opening").on("click", function () {
        const jobID = this.attributes[1].value;
        window.open("https://app.aarosjob.in/job/" + jobID, "_blank");
      });
    },
    (err) => {
      console.log(err);
    }
  );
};
