import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBAGsLaEbLUs637Q4TuKOY9KlWWw-I9Aq8",
  authDomain: "aarosjob-5cc24.firebaseapp.com",
  projectId: "aarosjob-5cc24",
  storageBucket: "aarosjob-5cc24.appspot.com",
  messagingSenderId: "1024135688933",
  appId: "1:1024135688933:web:ad8a709953a7c86b78da0c",
  measurementId: "G-BWVX7NPNWC",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

// if (location.hostname === "localhost") {
//   connectFirestoreEmulator(db, "127.0.0.1", 8080);
// }

export default app;
