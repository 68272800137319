import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["parts", "highlightedTagName", "nonHighlightedTagName", "separator", "className", "classNames"];
import { cx } from '@algolia/ui-components-shared';

function createHighlightPartComponent(_ref) {
  var createElement = _ref.createElement;
  return function HighlightPart(_ref2) {
    var classNames = _ref2.classNames,
        children = _ref2.children,
        highlightedTagName = _ref2.highlightedTagName,
        isHighlighted = _ref2.isHighlighted,
        nonHighlightedTagName = _ref2.nonHighlightedTagName;
    var TagName = isHighlighted ? highlightedTagName : nonHighlightedTagName;
    return createElement(TagName, {
      className: isHighlighted ? classNames.highlighted : classNames.nonHighlighted
    }, children);
  };
}

export function createHighlightComponent(_ref3) {
  var createElement = _ref3.createElement,
      Fragment = _ref3.Fragment;
  var HighlightPart = createHighlightPartComponent({
    createElement: createElement,
    Fragment: Fragment
  });
  return function Highlight(userProps) {
    // Not destructured in function signature, to make sure it's not exposed in
    // the type definition.
    var parts = userProps.parts,
        _userProps$highlighte = userProps.highlightedTagName,
        highlightedTagName = _userProps$highlighte === void 0 ? 'mark' : _userProps$highlighte,
        _userProps$nonHighlig = userProps.nonHighlightedTagName,
        nonHighlightedTagName = _userProps$nonHighlig === void 0 ? 'span' : _userProps$nonHighlig,
        _userProps$separator = userProps.separator,
        separator = _userProps$separator === void 0 ? ', ' : _userProps$separator,
        className = userProps.className,
        _userProps$classNames = userProps.classNames,
        classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
        props = _objectWithoutProperties(userProps, _excluded);

    return createElement("span", _extends({}, props, {
      className: cx(classNames.root, className)
    }), parts.map(function (part, partIndex) {
      var isLastPart = partIndex === parts.length - 1;
      return createElement(Fragment, {
        key: partIndex
      }, part.map(function (subPart, subPartIndex) {
        return createElement(HighlightPart, {
          key: subPartIndex,
          classNames: classNames,
          highlightedTagName: highlightedTagName,
          nonHighlightedTagName: nonHighlightedTagName,
          isHighlighted: subPart.isHighlighted
        }, subPart.value);
      }), !isLastPart && createElement("span", {
        className: classNames.separator
      }, separator));
    }));
  };
}